
import ClickOutside from "vue-click-outside";
import axios from "axios";
import { COUNTRIES } from "@/common/consts";

export default {
	name: "SmsInput",
	directives: {
		ClickOutside,
	},
	props: {
		type: String,
	},
	data: () => ({
		countries: COUNTRIES,
		selectedCountry: {
			code: "+1",
			name: "United States",
		},
		phoneNumber: "",
		formMessage: "",
		formStatus: "ready", // ready, pending, error, success
		countryDropdownIsOpen: false,
	}),
	computed: {
		hasNumber() {
			return this.phoneNumber.length > 0;
		},
		hasFormError() {
			return this.formStatus === "error";
		},
	},
	methods: {
		onFormSubmit() {
			this.formStatus = "ready";

			if (!this.selectedCountry.code.length) {
				this.formStatus = "error";
				this.formMessage = "ERROR: PLEASE SELECT A COUNTRY";
				return false;
			}

			// Check if the phone number has been entered.
			if (!this.phoneNumber.length) {
				this.formStatus = "error";
				this.formMessage = "ERROR: PLEASE ENTER YOUR PHONE NUMBER";
				return false;
			}

			// Check if the phone number is valid.
			const m = this.phoneNumber.match(/^\d+$/);
			if (m === null) {
				this.formStatus = "error";
				this.formMessage = "ERROR: PLEASE ENTER ONLY NUMBERS";
				return false;
			}

			this.formStatus = "pending";
			this.formMessage = "PLEASE WAIT ONE MOMENT...";

			const vm = this;

			const params = {
				phone_number: this.phoneNumber,
				country_code: this.selectedCountry.code,
			};

			if (this.type) {
				params.app = this.type;
			}

			axios
				.post(`${this.$config.API_SERVICE}/v1/invite`, params)
				.then((response) => {
					const data = response.data;
					if (typeof data === "object" && data.data === true) {
						vm.formStatus = "success";
						vm.formMessage = "YOU WILL RECEIVE A MESSAGE SHORTLY";
					} else if (
						typeof data === "object" &&
						data.data === false &&
						typeof data.message === "string"
					) {
						vm.formStatus = "error";
						vm.formMessage = data.message;
					} else {
						vm.formStatus = "error";
						vm.formMessage =
							"AN UNKNOWN ERROR HAS OCCURRED, PLEASE TRY AGAIN LATER";
					}
				});
		},
		onCountryDropdownButtonClick() {
			this.countryDropdownIsOpen = !this.countryDropdownIsOpen;
		},
		onCountryDropdownButtonClickOutside() {
			this.countryDropdownIsOpen = false;
		},
		onCountryDropdownEntryClick(country) {
			this.selectedCountry = Object.assign({}, country);
		},
	},
};
