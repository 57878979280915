
import { canonical } from "@utils";
export default {
	name: "AppAbout",
	data() {
		return {
			isAndroid: null,
			isIos: null,
		};
	},
	head() {
		return {
			link: [canonical(this.$route)],
		};
	},
	mounted() {
		const agent = window?.navigator?.userAgent || "";
		// eslint-disable-next-line prefer-regex-literals
		this.isAndroid = new RegExp("Android", "i").test(agent);
		// eslint-disable-next-line prefer-regex-literals
		this.isIos = new RegExp(
			"\\biPhone.*Mobile|\\biPod|\\biPad|AppleCoreMedia",
			"i"
		).test(agent);
	},
};
