import { render, staticRenderFns } from "./AppAbout.vue?vue&type=template&id=ceadf048&scoped=true&"
import script from "./AppAbout.vue?vue&type=script&lang=js&"
export * from "./AppAbout.vue?vue&type=script&lang=js&"
import style0 from "./AppAbout.vue?vue&type=style&index=0&id=ceadf048&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ceadf048",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmsInput: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/SmsInput/SmsInput.vue').default})
